/** @jsxImportSource @emotion/react */
import * as React from 'react';

import styled from '@emotion/styled';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import ContentWrapper from '~/components/ContentWrapper';
import LanguageDropdown from '~/components/LanguageDropdown';
import * as colors from '~/components/shared/colors';
import { EventData, IntlLink } from '~/components/shared/Link';
import * as screenSizes from '~/components/shared/screenSizes';
import linkMessages from '~/links/messages';

const currentYear = new Date().getFullYear();

const Outer = styled.footer`
  background-color: ${colors.outerSpace};
  padding: 35px 0;
  color: ${colors.white};

  @media (min-width: ${screenSizes.large}) {
    padding: 0;
    background-image: linear-gradient(
      to right,
      ${colors.outerSpace} 62%,
      white 62%
    );
  }
`;

const Inner = styled.div`
  @media screen and (min-width: ${screenSizes.large}) {
    padding: 35px 0;
    background-image: linear-gradient(
      to right,
      ${colors.outerSpace} 62%,
      white 62%
    );
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
  flex-wrap: wrap;
  line-height: 1.5;

  @media (min-width: ${screenSizes.huge}) {
    height: auto;
    flex-direction: row;
  }

  @media (min-width: ${screenSizes.large}) {
    max-width: 62%;
    padding-right: 15px;
  }

  ${screenSizes.mediaMaxWidthMedium} {
    text-align: center;
    align-items: center;
    justify-content: space-between;
  }
`;

export const BottomFooterLinks = styled.nav`
  display: flex;
  flex-wrap: wrap;

  ${screenSizes.mediaMaxWidthMedium} {
    justify-content: center;
  }

  a {
    color: inherit;
  }
`;

export const BottomFooterLinkContainer = styled.div`
  margin-right: 24px;
  display: flex;
  margin-bottom: 8px;

  @media (min-width: ${screenSizes.medium}) {
    text-align: center;
  }
`;

const FooterText = styled.div`
  font-family: 'Roboto';

  @media (min-width: ${screenSizes.medium}) {
    flex-grow: 1;
  }

  @media (min-width: ${screenSizes.large}) {
    text-align: left;
  }

  ${screenSizes.mediaMaxWidthLarge} {
    margin-top: 18px;
  }
`;

type BottomFooterEventProps = EventData<{ link: string }>;

interface BottomFooterDatum {
  translationId: string;
  hrefTranslation: MessageDescriptor;
  event: BottomFooterEventProps;
}

const makeBottomFooterEventProps = ({
  link,
}: {
  link: string;
}): BottomFooterEventProps => {
  return {
    name: 'clicked-footer',
    props: { link },
  };
};

const bottomFooterData: BottomFooterDatum[] = [
  {
    translationId: 'footer.linkTerms.text',
    hrefTranslation: linkMessages.termsOfUse,
    event: makeBottomFooterEventProps({ link: 'legal/terms-of-use' }),
  },
  {
    translationId: 'footer.linkPrivacyPolicy.text',
    hrefTranslation: linkMessages.privacyPolicy,
    event: makeBottomFooterEventProps({ link: 'privacy-policy' }),
  },
  {
    translationId: 'footer.linkCookiePolicy.text',
    hrefTranslation: linkMessages.cookiePolicy,
    event: makeBottomFooterEventProps({ link: 'cookie-policy' }),
  },
  {
    translationId: 'footer.linkSecurity.text',
    hrefTranslation: linkMessages.security,
    event: makeBottomFooterEventProps({ link: 'security' }),
  },
];

const BottomFooterLink = ({ data }) => {
  return (
    <BottomFooterLinkContainer>
      <IntlLink
        hrefTranslation={data.hrefTranslation}
        event={data.event}
      >
        <FormattedMessage id={data.translationId} />
      </IntlLink>
    </BottomFooterLinkContainer>
  );
};

const BottomFooter = () => {
  return (
    <BottomContainer>
      <BottomFooterLinks>
        {bottomFooterData.map((data, i) => {
          return (
            <BottomFooterLink
              key={`footer-${data.event.props?.link}-${i}`}
              data={data}
            />
          );
        })}
        <BottomFooterLinkContainer>
          <LanguageDropdown />
        </BottomFooterLinkContainer>
      </BottomFooterLinks>
      <FooterText>
        <FormattedMessage
          id='footer.copyright'
          values={{
            currentYear: currentYear,
          }}
        />
      </FooterText>
    </BottomContainer>
  );
};

const Footer = (): React.ReactElement => {
  return (
    <Outer>
      <ContentWrapper>
        <Inner>
          <BottomFooter />
        </Inner>
      </ContentWrapper>
    </Outer>
  );
};

export default Footer;
