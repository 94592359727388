import * as React from 'react';

import styled from '@emotion/styled';

import * as screenSizes from '~/components/shared/screenSizes';

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  padding: 0 15px;

  @media (min-width: ${screenSizes.medium}) {
    max-width: 750px;
  }

  @media (min-width: ${screenSizes.large}) {
    max-width: 1000px;
  }

  @media (min-width: ${screenSizes.huge}) {
    max-width: 1200px;
  }

  ${screenSizes.mediaMaxWidthMedium} {
    max-width: 100%;
    padding: 0;
  }

  @media (min-width: 1600px) {
    max-width: 1400px;
  }
`;

export default ({ children }: { children: React.ReactNode }) => {
  return <Wrapper>{children}</Wrapper>;
};
