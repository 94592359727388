export const white = '#FFFFFF';
export const white100 = '#F5F5F5';

export const black100 = '#161616';
export const black200 = '#343434';
export const black300 = '#898989';
export const black400 = '#183854';

export const blue100 = '#27A0B6';
export const blue200 = '#36D4ED';
export const blue300 = '#286090';

export const green100 = '#04AA51';
export const green200 = '#2ABD4A';

export const red500 = '#D63C2E';

export const outerGreen = '#049B4A';
export const outerGreenDark = '#048C43';
export const outerGreenDarker = '#00701B';

export const outerSpace = '#003740';
export const outerBlueDark = '#0062B0';
export const outerBlueDarkest = '#004A95';

export const bitbucketBlue = '#0078ca';

export const neutral70 = '#E3E3E3';
