import { parse as parseUrl, format as formatUrl } from 'url';

import * as React from 'react';

import { TrackAction } from '@circleci/analytics/build/src/hooks/useTrackAction';
import styled from '@emotion/styled';
import NextLink from 'next/link';
import { MessageDescriptor, useIntl } from 'react-intl';

import { trackAction } from '~/backplane/analytics';
// jsx has to be imported for @emotion/core css to work but
// it is never explicitly used. This tricks eslint and typescript
// into thinking it is used
import * as colors from '~/components/shared/colors';
import wait from '~/utils/wait';

export interface EventData<T> {
  name: string;
  props?: T;
}

type GenericEventData = EventData<object>;

const track = (
  event: EventData<{ cta_text?: string } | undefined> | undefined,
) => {
  return (clickEvent: React.MouseEvent<HTMLAnchorElement>): void => {
    const target = clickEvent.target as HTMLElement;
    const customCtaText = event?.props?.['cta_text'];

    const defaultProps = {
      ['cta_text']: customCtaText || target.textContent?.trim(),
    };

    if (event) {
      trackAction(event.name, { ...event.props, ...defaultProps });
    }
  };
};

type Theme = 'blue' | 'blueEnterprise' | 'white' | 'black';

interface LinkProps {
  children: React.ReactNode;
  href: string;
  event?: GenericEventData;
  theme?: Theme;
  as?: string;
  trackLinkAction?: TrackAction;
  trackViaProxy?: boolean;
  isCenter?: boolean;
}

interface IntlLinkProps {
  children: React.ReactNode;
  hrefTranslation: MessageDescriptor;
  event?: GenericEventData;
  theme?: Theme;
  pageHref?: string;
  query?: Map<string, string>;
  trackLinkAction?: TrackAction;
  trackViaProxy?: boolean;
  isCenter?: boolean;
}

const BaseLink = styled('a')<{ isCenter?: boolean }>(
  ({ isCenter }) =>
    `
  color: ${colors.outerBlueDark};
  text-decoration: none;
   ${
     isCenter &&
     `
     justify-content: center;
     `
   }
  `,
);

const Link = ({
  children,
  href,
  event,
  as,
  trackLinkAction,
  trackViaProxy,
  isCenter,
}: LinkProps): React.ReactElement => {
  const isExternalLink = /https?:\/\//.test(href);

  if (isExternalLink) {
    return (
      <BaseLink
        onClick={async (e): Promise<void> => {
          // This allows us to keep href for accessibility.
          e.preventDefault();
          if (trackLinkAction) {
            trackLinkAction('clicked', {}, { server: trackViaProxy });
          } else {
            track(event)(e);
          }
          // Segment suggests waiting 300 ms for events to be sent.
          // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
          await wait(300);
          window.location.href = href;
        }}
        href={href}
        isCenter={isCenter}
      >
        {children}
      </BaseLink>
    );
  }
  return (
    <NextLink
      href={href}
      passHref
      prefetch={false}
      as={as}
    >
      <BaseLink
        onClick={
          trackLinkAction
            ? (): Promise<void> => {
                return trackLinkAction(
                  'clicked',
                  {},
                  { server: trackViaProxy },
                );
              }
            : track(event)
        }
        isCenter={isCenter}
      >
        {children}
      </BaseLink>
    </NextLink>
  );
};

export const getQueryParams = (url: string): Map<string, string | string[]> => {
  const { query } = parseUrl(url ?? '', true);
  return new Map(Object.entries(query));
};

export const setQueryParam = (
  url: string,
  keyValuePairs: Map<string, string>,
): string => {
  const { protocol, host, pathname, query, hash } = parseUrl(url, true);

  keyValuePairs.forEach((value, key) => {
    query[key] = value;
  });

  return formatUrl({
    protocol,
    host,
    pathname,
    query,
    hash,
  });
};

export const IntlLink: React.FC<IntlLinkProps> = ({
  children,
  hrefTranslation,
  event,
  theme,
  pageHref,
  query = new Map(),
  trackLinkAction,
  trackViaProxy,
  isCenter,
}) => {
  const intl = useIntl();
  const localizedPath = intl.formatMessage(hrefTranslation);
  const queryWithLanguage = new Map().set('lang', intl.locale);
  const href = pageHref
    ? setQueryParam(pageHref, queryWithLanguage)
    : setQueryParam(localizedPath, query);
  const as = pageHref ? setQueryParam(localizedPath, query) : pageHref;
  return (
    <Link
      isCenter={isCenter}
      href={href}
      event={event}
      theme={theme}
      as={as}
      trackLinkAction={trackLinkAction}
      trackViaProxy={trackViaProxy}
    >
      {children}
    </Link>
  );
};

export default Link;
